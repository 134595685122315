import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landingpage from './Pages/Landingpage';
import TrendingTags from './Pages/TrendingTags';
import TrendingPage from './Pages/TrendingPage';
import TrendDetailPage from './Pages/TrendingDetailPage';
import AccessPage from './Pages/AccessPage';

function App() {
  const BuyMeACoffeeButton = () => {
    return (
      <a href="https://buymeacoffee.com/greghalpern" target="_blank" rel="noopener noreferrer" className="fixed bottom-4 right-4 bg-yellow-500 text-white p-3 rounded-full shadow-lg">
        <span role="img" aria-label="coffee cup">☕</span> Buy Me A Coffee
      </a>
    );
  };
  return (
    <>
    <TrendingTags/>
    <BrowserRouter>

    <BuyMeACoffeeButton />
    
    <Routes>
      <Route path="/" element={<AccessPage/>} />
      {/* <Route path="/Save-America" element={<TrendingPage/>} /> */}
      {/* <Route path="/Landingpage" element={<Landingpage/>} /> */}
      {/* <Route path="/Save-America/:url" element={<TrendDetailPage/>} /> */}


      
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
